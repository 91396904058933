import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useModal } from '@gluedigital/modal'
import { getImageSize } from 'src/util'
import './ExperienceAlbumModal'

import 'isomorphic-fetch'
import './ExperienceContent.sass'

const Image = ({ image, index, total, onClick }) => {
  const url = getImageSize(image, 'mid')
  let style = url ? { backgroundImage: 'url("' + url + '")' } : null
  if (index === 5 && (total - index - 1) > 0) {
    return (
      <div className="image" style={style} onClick={onClick}>
        <div className="images-rest">
          <strong className="amount">
            {total - index - 1}
            +
          </strong>
        </div>
      </div>
    )
  } else {
    return <div className="image" style={style} onClick={onClick} />
  }
}

const ExperienceContent = ({ experienceReq, experiencesReq, params }) => {
  const modal = useModal()

  const experiences = experiencesReq.value
  const experiencesFilter = experiences && experiences.filter((exp) => exp.slug === params.experienceId)
  let experience = experienceReq.value || experienceReq.oldValue ||
    ((experiencesFilter && experiencesFilter.length > 0) ? experiencesFilter[0] : { isLoading: true })
  if (!experience) {
    if (experienceReq.isLoading) return 'Loading'
    if (experienceReq.isError) return 'Error'
    return false
  }

  const images = experience.images
  const showAlbum = (idx) => () => modal.show('experience-album', {
    images,
    defaultIndex: idx
  })

  return (
    <div id="experience-content">
      <section id="experience-body" itemProp="articleBody">
        <div className="container">
          <div id={'post-' + experience.slug} className="post">
            <div itemProp="author" id="author">
              <span className="icon icon-user" />
              <span>{experience.author || 'Anónimo'}</span>
            </div>
            <h3 itemProp="name" className="title"> {experience.name} </h3>
            <div itemProp="post-content" id={'post-content-' + experience.slug} className="post-content"
              dangerouslySetInnerHTML={{ __html: experience.description }} />
          </div>
        </div>
      </section>
      <section id="experience-images">
        {images.slice(0, 6).map((image, i) =>
          <Image image={image} key={i} index={i}
            total={images.length} onClick={showAlbum(i)} />
        )}
      </section>
    </div>
  )
}

ExperienceContent.propTypes = {
  experienceReq: PropTypes.object.isRequired,
  experiencesReq: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  experienceReq: state.experiences.experience || {},
  experiencesReq: state.experiences.experiences || {}
})
export default connect(mapStateToProps)(ExperienceContent)
