import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { getExperiences } from 'src/store/actions/experiences'
import SocialFloater from 'src/components/SocialFloater'
import MouseAnimation from 'src/components/MouseAnimation'
import config from 'src/config'
import { getImageSize } from 'src/util'
import 'isomorphic-fetch'

import './Home.sass'

const FeaturedCard = ({ experience }) => {
  const url = getImageSize(experience.background, 'mid')
  let style = url ? { backgroundImage: 'url("' + url + '")' } : null
  return (
    <div className="featured-experience" key={experience.slug}>
      <Link to={'/experience/' + experience.slug}>
        <div className="featured-image" style={style} />
        <div className="featured-data">
          <div className="price">
            <MarkdownMessage id="home.featured.price" values={{ n: experience.price || '000' }} />
          </div>
          <div className="location">
            <div className="city">
              {experience.city || 'Ciudad'}
            </div>
            <div className="country">
              {experience.country || 'País'}
            </div>
          </div>
          <div className="author">{experience.author || 'Anónimo'}</div>
        </div>
      </Link>
    </div>
  )
}

FeaturedCard.propTypes = {
  experience: PropTypes.object.isRequired
}

const Card = ({ experience }) => {
  const url = getImageSize(experience.background, 'mid')
  let style = url ? { backgroundImage: 'url("' + url + '")' } : null

  const renderDotsTags = (tags, i) => {
    if (tags.length > 4) {
      return <div key={4} className="tag"> ... </div>
    }
  }

  return (
    <div className="experience" key={experience.id} style={style}>
      <div className="veil" />
      <Link to={'/experience/' + experience.slug}>
        <div className="top-data">
          <div className="price">
            <div className="since"><FormattedMessage id="since" /></div>
            <div className="amount">{experience.price || '000'} €</div>
            <div className="people"><FormattedMessage id="home.per-person" /></div>
          </div>
          {experience.hotPrice ? <div className="hot-price icon icon-fire" /> : ''}
        </div>
        <div className="bottom-data">
          <div className="breadcrumb">
            <span className="crumb">{experience.continent || 'Continente'}</span>
            <span className="crumb">{experience.country || 'País'}</span>
            <span className="crumb">{experience.city || 'Ciudad'}</span>
          </div>
          <h2>{experience.name}</h2>
          <div className="author">{experience.author || 'Anónimo'}</div>
          <div className="tags">
            {experience.tags.slice(0, 4).map((tag, i) => <div key={i} className="tag">{tag.name}</div>)}
            {renderDotsTags(experience.tags)}
          </div>
        </div>
      </Link>
    </div>
  )
}

Card.propTypes = {
  experience: PropTypes.object.isRequired
}

const Home = ({ experiencesReq }) => {

  const shadowExperience = {
    background: {
      url: config.PLACEHOLDER_COLOR
    },
    tags: []
  }
  const experiences = experiencesReq.value || experiencesReq.oldValue || Array(10).fill(shadowExperience)

  if (experiencesReq.isError) return 'Error'

  const superfeaturedExperience = experiences[0] || false
  const superFeaturedUrl = superfeaturedExperience && getImageSize(superfeaturedExperience.background, 'big')
  const superFeaturedStyle = superFeaturedUrl ? { backgroundImage: 'url("' + superFeaturedUrl + '")' } : null

  const [activeFilters, setActiveFilters] = useState([])
  const toggleFilter = (e, continent) => {

    let newActiveFilter
    if (activeFilters.includes(continent)) {
      newActiveFilter = activeFilters.filter((filter) => filter !== continent)
    } else {
      const cloneActiveFilters = [...activeFilters]
      newActiveFilter = cloneActiveFilters.concat(continent)
    }
    setActiveFilters(newActiveFilter)
  }

  const renderFilters = () => {
    const continents = ['Asia', 'África', 'Europa', 'América', 'Oceanía']
    return continents.map((continent, i) => {
      let classFilter = 'filter'
      if (activeFilters.includes(continent)) {
        classFilter += ' active'
      }
      return (<div key={i} className={classFilter} onClick={(e) => toggleFilter(e, continent)}>{continent}</div>)
    })
  }

  const renderExperiences = () => {
    let experiencesFilter
    if (activeFilters.length > 0) {
      experiencesFilter = experiences.filter((experience) => activeFilters.includes(experience.continent))
    } else {
      experiencesFilter = experiences
    }
    return experiencesFilter.map((experience, i) =>
      <Card experience={experience} key={i} />
    )
  }

  return (
    <div id="home" className="page">
      <header id="super-featured" style={superFeaturedStyle}>
        <div className="veil" />
        <div className="experience-data">
          <h1>{superfeaturedExperience.name}</h1>
          <SocialFloater location="/" title={superfeaturedExperience.name} />
          <div className="price">
            <MarkdownMessage id="home.super-featured.price" values={{ n: superfeaturedExperience.price || '000' }} />
          </div>
          <Link className="button" to={'/experience/' + superfeaturedExperience.slug}>
            <FormattedMessage id="know-more" />
          </Link>
        </div>
        <div id="featured-experiences">
          <h3><FormattedMessage id="home.featured-title" /></h3>
          <div className="featured-wrapper">
            {experiences.slice(1, 3).map((featuredExperience, i) =>
              <FeaturedCard experience={featuredExperience} key={i} />
            )}
          </div>
        </div>
        <MouseAnimation anchor="experiences" />
      </header>
      <section id="experiences">
        <h1><FormattedMessage id="home.experiences.title" /></h1>
        <div className="filters">
          <div className="filter-bar">
            {renderFilters()}
          </div>
        </div>
        <div className="experience-grid">
          {renderExperiences()}
        </div>
      </section>
    </div>
  )
}

Home.fetchData = (props, dispatch) => dispatch(getExperiences())

Home.propTypes = {
  experiencesReq: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ experiencesReq: state.experiences.experiences || {} })

export default connect(mapStateToProps)(Home)
