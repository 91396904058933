import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import 'isomorphic-fetch'
import './ExperienceRoutes.sass'

const HotelStars = ({ stars = 0 }) => {
  const ret = []
  for (var i = 0; i < 5; i++) {
    ret.push(<span key={i} className={'icon icon-star ' + (stars > i ? 'on' : 'off')} />)
  }
  return ret
}

HotelStars.propTypes = {
  stars: PropTypes.number
}
const HotelData = ({ hotel }) => {
  if (hotel.hotelName || hotel.roomType || hotel.hotelStars) {
    return (
      <div className="hotel-data">
        <div className="hotel-name">
          <strong className="name">{hotel.hotelName}</strong>
        </div>
        <div className="hotel-stars">
          <HotelStars stars={parseInt(hotel.hotelStars, 10)} />
        </div>
        <div className="room-type">
          <strong className="type">{hotel.roomType}</strong>
        </div>
      </div>
    )
  } else {
    return null
  }
}

HotelData.propTypes = {
  hotel: PropTypes.object
}

const ItineraryCard = ({ day, dayNumber }) => {
  const url = day.image && day.image.url
    ? day.image.url : null
  const style = url ? { backgroundImage: 'url("' + url + '")' } : null

  const renderDaysItinerary = (day) => {
    if (!day.activities) return false
    return day.activities.map((activity, i) =>
      <div key={i} className="activity">
        <p>{activity.description}</p>
      </div>
    )
  }

  return (
    <div className="itinerary-card day">
      <div className="day-image" style={style}>
        <div className="veil" />
        <h1><FormattedMessage id="experience.day" values={{ n: dayNumber }} /></h1>
        <h3>{day.guide || 'sin guía'}</h3>
        <h2>{day.title}</h2>
      </div>
      <div className="day-data">
        <HotelData hotel={day.hotel} />
        <div className="activities">
          {renderDaysItinerary(day)}
        </div>
      </div>
    </div>
  )
}

ItineraryCard.propTypes = {
  day: PropTypes.object.isRequired,
  dayNumber: PropTypes.number.isRequired
}

const ExperienceRoutes = ({ experienceReq }) => {
  const experience = experienceReq.value || experienceReq.oldValue
  if (!experience) {
    if (experienceReq.isLoading) return 'Loading'
    if (experienceReq.isError) return 'Error'
    return false
  }

  const renderItinerariesCard = (experience) => {
    if (!experience.itinerary) return false
    return experience.itinerary.map((day, i) => {
      return <ItineraryCard day={day} key={i} dayNumber={i + 1} />
    })
  }

  return (
    <div id="experience-routes">
      <section id="experience-body">
        <div className="container">
          <h1 itemProp="name" className="title"> {experience.name} </h1>
          <div className="intro"
            dangerouslySetInnerHTML={{ __html: experience.itineraryIntro }} />
          <div className="itinerary">
            <h1><FormattedMessage id="experience.itinerary-title" /></h1>
            {renderItinerariesCard(experience)}
            <div className="split">
              <div className="item left">
                <div className="included">
                  <h2><FormattedMessage id="experience.itinerary.included" /></h2>
                  <div className="included-wrapper"
                    dangerouslySetInnerHTML={{ __html: experience.includedServices }} />
                </div>
              </div>
              <div className="item right">
                <div className="not-included">
                  <h2><FormattedMessage id="experience.itinerary.not-included" /></h2>
                  <div className="not-included-wrapper"
                    dangerouslySetInnerHTML={{ __html: experience.notIncludedServices }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

ExperienceRoutes.propTypes = {
  experienceReq: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ experienceReq: state.experiences.experience || {} })
export default connect(mapStateToProps)(ExperienceRoutes)
