import React from 'react'
import { IndexRoute, Router, Route } from 'react-router'
import Layout from 'src/layouts/Layout'
import Home from './Home'
import Promotions from './Promotions'
import Experience from './Experience'
import ExperienceContent from './Experience/ExperienceContent'
import ExperienceRoutes from './Experience/ExperienceRoutes'
import About from './About'
import Privacy from './Privacy'
import NotFound from './NotFound'
import Map from './Map'

export const error500 = 'src/routes/error500.html'

const routes = () => {

  const panelRoutes = <Route path="admin" getChildRoutes={(location, cb) => {
    import(/* webpackChunkName: "autopanel" */ './panelRoutes')
      .then(({ default: panel }) => cb(null, panel))
  }} />
  const adminFallback = <Route path="admin/*" component={() => '...'} />
  const panel = __CLIENT__ ? panelRoutes : adminFallback

  return (
    <Router>
      {panel}
      <Route component={Layout} path="/">
        <IndexRoute component={Home} title="home" />
        <Route component={Experience} path="experience/:experienceId">
          <IndexRoute component={ExperienceContent} title="experience" />
          <Route path="route" component={ExperienceRoutes} title="route" />
        </Route>
        <Route path="promotions" component={Promotions} title="promotions" />
        <Route path="map" component={Map} title="map" />
        <Route path="about" component={About} title="about" />
        <Route path="privacy" component={Privacy} title="privacy" />
        <Route path="*" component={NotFound} title="not-found" />
      </Route>
    </Router>
  )
}

export default routes
