import React from 'react'
import { render } from 'react-dom'
import ExperienceCard from './ExperienceCard'

const L = __CLIENT__ ? require('leaflet') : null

export const ExperienceIcon = L && L.Icon.extend({
  createIcon: function (oldIcon) {
    const div = oldIcon || document.createElement('div')
    render(
      <ExperienceCard
        values={this.options.experience}
        router={this.options.router}
        intl={this.options.intl}
      />
      , div)
    this._setIconStyles(div, 'icon')
    return div
  },

  createShadow: () => null
})

export const experienceIcon = (options) => new ExperienceIcon(options)

export default experienceIcon
