import 'src/styles/main.sass'

// Other deps
import Scroll from 'scroll'

// Smooth scroll on anchor links
const scrollable = require('scroll-doc')()
document.addEventListener('click', (e) => {
  let target = e.target
  // Bubble up checking if any ancestor is an <a>
  while (target && target.tagName !== 'A') target = target.parentNode
  if (target && target.tagName === 'A') {
    // Check if link contains anchor
    if (target.hash.length > 1) {
      // Check if it is to the same page
      if (target.pathname === window.location.pathname) {
        const dest = document.getElementById(target.hash.substring(1))
        if (!dest) return // No target on page ?
        e.preventDefault()
        let y = dest.getBoundingClientRect().top + scrollable.scrollTop
        if (target.dataset['offset']) y -= parseInt(target.dataset['offset'])
        Scroll.top(scrollable, y, { duration: 500 })
      }
    }
  }
})
