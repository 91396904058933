import React from 'react'
import { FormattedMessage } from 'react-intl'

import './NotFound.sass'

export const NotFound = () => {
  return (
    <div id="not-found" className="page">
      <section id="intro">
        <div className="ufo-illustration" />
        <h3><FormattedMessage id="not-found.title" /></h3>
      </section>
    </div>
  )
}

NotFound.fetchData = () => {
  return { __serverDirectives: { status: 404 } }
}

export default NotFound
