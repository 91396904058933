import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ModalWrapper, Modal } from '@gluedigital/modal'

import Metas from './Metas'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import CookieConsent from '@gluedigital/cookie-consent'
import pubvendors from 'src/pubvendors.json'

import './Layout.sass'

let path = false
const useScroll = (page) => {
  useEffect(() => {
    if (!path) {
      path = page
    } else if (path !== page && (path !== 'route' || page !== 'experience')) {
      window.scrollTo(0, 0)
      path = page
    }
  }, [page])
}

export const Layout = (props) => {
  let content = props.children

  const routes = props.router.routes
  const lastRoute = routes[routes.length - 1]
  let pageName = lastRoute.title
  useScroll(pageName)

  return (
    <CookieConsent pubvendors={pubvendors}>
      <ModalWrapper>
        <main id="layout">
          <Metas />
          <Header pageName={pageName} />
          {content}
          <Footer pageName={pageName} />
          <Modal />
        </main>
      </ModalWrapper>
    </CookieConsent>
  )
}

Layout.propTypes = {
  router: PropTypes.object.isRequired,
  children: PropTypes.element
}

export default Layout
