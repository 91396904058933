import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { registerModal } from '@gluedigital/modal'
import { getImageSize } from 'src/util'

import './ExperienceAlbumModal.sass'

const ExperienceAlbumModal = ({ images, defaultIndex }) => {
  const [currIdx, setIdx] = useState(defaultIndex)
  const bigUrl = getImageSize(images[currIdx], 'big')
  const bigStyle = { backgroundImage: 'url("' + bigUrl + '")' }
  return (
    <div className="experience-album">
      <div className="big-pic" style={bigStyle} />
      <div className="all-pics">
        {images.map((image, i) => {
          const url = getImageSize(image, 'mid')
          const style = { backgroundImage: 'url("' + url + '")' }
          return <div className="small-pic" key={i} style={style}
            onClick={() => setIdx(i)} />
        })}
      </div>
    </div>
  )
}

ExperienceAlbumModal.propTypes = {
  images: PropTypes.array.isRequired,
  defaultIndex: PropTypes.number.isRequired
}

registerModal('experience-album', ExperienceAlbumModal)
