import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import xuntaGrant from '../../static/images/xunta-grant.jpg'

import './Footer.sass'

export const Footer = (props) => {
  return (
    <footer id="colophon" className={props.pageName}>
      <div className="container">
        <Link className="logo" to="/" />
        <div className="links">
          <Link className="link" to="/">
            <FormattedMessage id="footer.home" />
          </Link>
          <Link className="link" to="/promotions">
            <FormattedMessage id="footer.promotions" />
          </Link>
          <Link className="link" to="/about">
            <FormattedMessage id="footer.about" />
          </Link>
          <Link className="link" to="/privacy">
            <FormattedMessage id="footer.privacy" />
          </Link>
        </div>
        <div className="social">
          <a
            target="_blank"
            href="https://facebook.com/vibeviajes/"
            rel="noopener"
          >
            <span className="icon icon-facebook" />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/vibeviajes/"
            rel="noopener"
          >
            <span className="icon icon-instagram" />
          </a>
          <a target="_blank" href="mailto:info@vibeviajes.com">
            <span className="icon icon-mail" />
          </a>
        </div>
        <img src={xuntaGrant} className="xunta-img" />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  pageName: PropTypes.string.isRequired
}

export default Footer
