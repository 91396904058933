import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage, FormattedDate } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import ContactForm from 'src/components/ContactForm'
import { getImageSize } from 'src/util'

import './PromotionContactModal.sass'

const PromotionData = ({ promotion }) => {
  return (
    <div id="promotion-data">
      <div className="data">
        <div className="wrapper-data">
          <div className="type">{promotion.type}</div>
          <h1 className="title">{promotion.title}</h1>
          <FormattedMessage id="promotions.origin" values={{ location: promotion.location || 'sin origen' }} />
        </div>
        <div className="prices">
          <div className="price">
            <MarkdownMessage id="home.featured.price"
              values={{ n: promotion.oldPrice || '000' }} />
            <div className="discount">
              -{promotion.discount || (promotion.newPrice && promotion.oldPrice
                ? Math.floor((-promotion.newPrice * 100 / promotion.oldPrice) + 100) : '0')}%
            </div>
          </div>
          <div className="new-price">
            <div className="new amount">{promotion.newPrice || '000'} €</div>
          </div>
        </div>
      </div>
      <div className="duration">
        <strong className="start date">
          <FormattedDate
            value={new Date(promotion.startDate)}
            day="2-digit"
          />
          <FormattedDate
            value={new Date(promotion.startDate)}
            month="long"
          />
        </strong>
        <span className="date-separator"> - </span>
        <strong className="end date">
          <FormattedDate
            value={new Date(promotion.endDate)}
            day="2-digit"
          />
          <FormattedDate
            value={new Date(promotion.endDate)}
            month="long"
          />
        </strong>
      </div>
      <p className="description" dangerouslySetInnerHTML={{ __html: promotion.contactDescription }} />
    </div>
  )
}
PromotionData.propTypes = {
  promotion: PropTypes.object
}

const PromotionContactModal = ({ promotion }) => {

  const url = getImageSize(promotion.background, 'big')
  const style = url ? { backgroundImage: 'url("' + url + '")' } : null

  const [showData, setShowData] = useState(false)
  const handleData = useCallback(
    () => {
      setShowData(!showData)
    },
    [showData]
  )

  let classToggleData = 'split'
  let classSwitcher = 'switcher show-form'
  if (showData) {
    classToggleData += ' show-data'
    classSwitcher = 'switcher show-info'
  }

  return (
    <div className="promotion-contact">
      <div className={classToggleData}>
        <div className={classSwitcher} onClick={handleData}>
          <FormattedMessage id="form" />
          <FormattedMessage id="info" />
        </div>
        <div className="item left" style={style}>
          <div className="veil" />
          <PromotionData promotion={promotion} />
        </div>
        <div className="item right">
          <ContactForm sourceType="promotion" sourceId={promotion.id} />
        </div>
      </div>
    </div>
  )
}

PromotionContactModal.propTypes = {
  promotion: PropTypes.object
}

registerModal('promotion-contact', PromotionContactModal)
