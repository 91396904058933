import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { getImageSize } from 'src/util'
import './ExperienceCard.sass'

export const ExperienceCard = (props) => {

  const [card, setCard] = useState(false)

  const handleCard = useCallback(
    () => {
      setCard(!card)
    },
    [card]
  )

  let classToggleCard = ''
  if (card) {
    classToggleCard += 'visible'
  }

  const url = getImageSize(props.values.background, 'mid')
  const style = url ? { backgroundImage: 'url("' + url + '")' } : null

  return (
    <div id="experience-card" className={classToggleCard}>
      <div className="point" onClick={handleCard} />
      <div className="link" onClick={() => props.router.push('/experience/' + props.values.slug)}>
        <div className="buble">
          <div className="image" style={style} />
          <div className="text">
            <div className="price">
              <div className="from">
                {props.intl.formatMessage({ id: 'experienceCard.from' }) }
              </div>
              <div className="value">{(props.values.price || '000 ') + ' €' }</div>
            </div>
            <div className="name">{props.values.city}</div>
            <div className="country">{props.values.country}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

ExperienceCard.propTypes = {
  values: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default ExperienceCard
