import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Helmet } from 'react-helmet'
import 'isomorphic-fetch'
import { useModal } from '@gluedigital/modal'
import { FormattedMessage, FormattedDate } from 'react-intl'
import config from 'src/config'
import MarkdownMessage from '@gluedigital/markdown-message'
import SocialFloater from 'src/components/SocialFloater'
import MouseAnimation from 'src/components/MouseAnimation'
import { getPromotions } from '../../store/actions/experiences'
import { getImageSize } from 'src/util'
import NotFound from 'src/routes/NotFound'
import ogImage from 'src/static/images/default-promotion-image.jpg'

import './PromotionContactModal'
import './Promotions.sass'

const EmptyPromotionList = () => {
  return (
    <div id="promotions" className="page">
      <header id="promotions-header">
        <div className="veil" />
        <div className="data">
          <FormattedMessage id="promotions.noexist" />
        </div>
      </header>
    </div>
  )
}

const PromotionList = ({ promotions }) => {
  const featuredPromotion = promotions[0] || false
  const featuredUrl = featuredPromotion && getImageSize(featuredPromotion.background, 'big')
  const featuredStyle = featuredUrl ? { backgroundImage: 'url("' + featuredUrl + '")' } : null

  const modal = useModal()
  const showFeaturedContact = () => modal.show('promotion-contact', { promotion: featuredPromotion })
  return (
    <div id="promotions" className="page">
      <header id="promotions-header" style={featuredStyle}>
        <div className="veil" />
        <div className="data">
          <FormattedMessage id="promotions.origin"
            values={{ location: featuredPromotion.location || 'sin origen' }} />
          <h1>{featuredPromotion.title}</h1>
          <div className="duration">
            <strong className="start date">
              <FormattedDate
                value={new Date(featuredPromotion.startDate)}
                day="2-digit"
              />
              <FormattedDate
                value={new Date(featuredPromotion.startDate)}
                month="short"
              />
            </strong>
            <span className="date-separator"> - </span>
            <strong className="end date">
              <FormattedDate
                value={new Date(featuredPromotion.endDate)}
                day="2-digit"
              />
              <FormattedDate
                value={new Date(featuredPromotion.endDate)}
                month="short"
              />
            </strong>
          </div>
          <div className="price">
            <MarkdownMessage id="home.super-featured.price"
              values={{ n: featuredPromotion.oldPrice || '000' }} />
            <div className="discount">
              -{featuredPromotion.discount || (featuredPromotion.newPrice && featuredPromotion.oldPrice
                ? Math.floor((-featuredPromotion.newPrice * 100 / featuredPromotion.oldPrice) + 100) : '0')}%
            </div>
          </div>
          <div className="new-price">{featuredPromotion.newPrice || '000'} €</div>
          <SocialFloater location="/promotions" title={featuredPromotion.title} />
          <div className="hire">
            <Link className="button" onClick={showFeaturedContact}>
              <FormattedMessage id="promotions.button.hire" />
            </Link>
          </div>
        </div>
        <MouseAnimation anchor="promotions-grid" />
      </header>
      <section id="promotions-grid">
        <div className="container">
          <h1><FormattedMessage id="promotions.promotions-grid.title" /></h1>
          <div className="promotions-list">
            {promotions.map((promotion, i) =>
              <Card promotion={promotion} key={i} />
            )}
          </div>
        </div>
      </section>
    </div>
  )
}
PromotionList.propTypes = {
  promotions: PropTypes.array.isRequired
}

const Card = ({ promotion }) => {
  const url = getImageSize(promotion.background, 'mid')
  let style = url ? { backgroundImage: 'url("' + url + '")' } : null

  const modal = useModal()
  const showContact = () => modal.show('promotion-contact', { promotion })
  return (

    <div className="promotion" key={promotion.id}>
      <div className="dates-info">
        <div className="duration">
          <strong className="start date">
            <FormattedDate
              value={new Date(promotion.startDate)}
              day="2-digit"
            />
            <FormattedDate
              value={new Date(promotion.startDate)}
              month="short"
            />
          </strong>
          <span className="date-separator"> - </span>
          <strong className="end date">
            <FormattedDate
              value={new Date(promotion.endDate)}
              day="2-digit"
            />
            <FormattedDate
              value={new Date(promotion.endDate)}
              month="short"
            />
          </strong>
        </div>
        <div className="available">
          <FormattedMessage id="promotions-expiration" />
          <strong className="expiration date">
            <FormattedDate
              value={new Date(promotion.expirationDate)}
              day="2-digit"
            />
            <FormattedDate
              value={new Date(promotion.expirationDate)}
              month="short"
            />
          </strong>
        </div>
      </div>
      <div className="card" style={style} onClick={showContact}>
        <div className="veil" />
        <div className="top-data">
          <div className="type">
            {promotion.type}
          </div>
          <div className="prices">
            <div className="price">
              <MarkdownMessage id="home.featured.price"
                values={{ n: promotion.oldPrice || '000' }} />
              <div className="discount">
                -{promotion.discount || (promotion.newPrice && promotion.oldPrice
                  ? Math.floor((-promotion.newPrice * 100 / promotion.oldPrice) + 100) : '0')}%
              </div>
            </div>
            <div className="new-price">
              <div className="new amount">{promotion.newPrice || '000'} €</div>
            </div>
          </div>
        </div>
        <div className="bottom-data">
          <h2>{promotion.title}</h2>
          <h3>
            <FormattedMessage id="promotions.origin" values={{ location: promotion.location || 'sin origen' }} />
          </h3>
          <p>{promotion.description}</p>
        </div>
      </div>
      <div className="hire-info">
        <FormattedMessage id="promotions.disponibility" />
        <div className="hire">
          <Link className="button" onClick={showContact}>
            <FormattedMessage id="promotions.button.hire" />
          </Link>
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  promotion: PropTypes.object.isRequired
}

const Promotions = ({ promotionsReq }) => {
  const shadowPromotions = {
    background: {
      url: config.PLACEHOLDER_COLOR
    }
  }
  const promotions = promotionsReq.value || promotionsReq.oldValue || [shadowPromotions]
  if (promotionsReq.isError) return <NotFound />

  const featuredPromotion = promotions[0] || false
  const externalUrl = encodeURI(config.EXTERNAL_URL)
  const theTitle = featuredPromotion.title || 'Vibe - Travel different'
  return (
    <React.Fragment>
      <Helmet
        title={theTitle}
        meta={[
          {
            name: 'description',
            content: featuredPromotion.description || 'Vibe - Travel different'
          },
          {
            property: 'og:title',
            content: theTitle
          },
          {
            property: 'og:description',
            content: featuredPromotion.description || 'Vibe - Travel different'
          },
          {
            property: 'og:url',
            content: externalUrl + '/promotions/'
          },
          {
            property: 'og:image',
            content: featuredPromotion.background && featuredPromotion.background.url
              ? featuredPromotion.background.url.big : externalUrl + ogImage
          }
        ]}
      />
      {promotions.length > 0 ? <PromotionList promotions={promotions} /> : <EmptyPromotionList />}
    </React.Fragment>
  )
}

Promotions.fetchData = async (props, dispatch) => dispatch(getPromotions()).catch(() => {})

Promotions.propTypes = {
  promotionsReq: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ promotionsReq: state.experiences.promotions || {} })

export default connect(mapStateToProps)(Promotions)
