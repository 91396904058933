import networkAction from '@gluedigital/network-action'
import config from 'src/config'

const apiAction = networkAction.withDefaults({
  baseUrl: config.API_HOST + '/api'
})

export const getExperiences = () => {
  return apiAction({
    action: 'getExperiences',
    url: '/experience'
  })
}

export const getPromotions = () => {
  return apiAction({
    action: 'getPromotions',
    url: '/promotion'
  })
}

export const getExperience = (experienceId, access) => {
  const queryString = access ? '?access=' + access : ''
  return apiAction({
    action: 'getExperience',
    url: '/experiences/' + experienceId + queryString
  })
}

export const contact = (data) => apiAction({
  action: 'contact',
  url: '/contact',
  body: data
})
