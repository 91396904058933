/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react'
import privacy from 'raw-loader!./privacy.html'

import './Privacy.sass'

export const Privacy = () => {
  return (
    <div id="privacy" className="page">
      <section id="privacy-content">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: privacy }} />
        </div>
      </section>
    </div>
  )
}

export default Privacy
