import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useAtTop } from '@gluedigital/scrollastic'

import './SocialFloater.sass'

export const SocialFloater = (props) => {

  const title = props.title
  const link = encodeURIComponent(process.env.API_HOST + props.location)

  const activeClass = useAtTop() ? '' : ' active'

  return (
    <div className={'social-floater' + activeClass}>
      <div className="links">
        <a rel="tinywindow" target="tinywindow" className="social facebook"
          href={'https://www.facebook.com/sharer.php?u=' + link}>
          <span className="icon icon-facebook" />
        </a>
        <a rel="tinywindow" target="tinywindow" className="social twitter"
          href={'https://twitter.com/share?url=' + link + '&amp;text=' + title}>
          <span className="icon icon-twitter" />
        </a>
        <a target="_blank" className="social whatsapp"
          href={'whatsapp://send?text=' + title + ' - ' + link}>
          <span className="icon icon-whatsapp" />
        </a>
        <FormattedMessage id="social-share.text">
          {(txt) => {
            const href = 'mailto:?subject=' + title + '&body=' + txt + ' ' + link
            return (
              <a className="social mail" title="Share by Email" href={href}>
                <span className="icon icon-mail" />
              </a>
            )
          }}
        </FormattedMessage>
      </div>
      <div className="text">Comparte en</div>
    </div>
  )
}

SocialFloater.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
}

export default SocialFloater
