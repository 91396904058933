const config = {
  API_HOST: __SERVER__
    ? 'http://localhost:' + (process.env.PORT || 3000)
    : process.env.API_HOST || '/',
  EXTERNAL_URL: process.env.API_HOST || '/',
  UPLOADS_DIR: process.env.UPLOADS_DIR || 'src/static/uploads',
  PLACEHOLDER_COLOR: 'data:image/png;base64,' +
    'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8duuWJwAILQLl7Q3VeAAAAABJRU5ErkJggg=='
}
export default config
