import React from 'react'
import PropTypes from 'prop-types'

import './MouseAnimation.sass'

export const MouseAnimation = (props) => {

  return (
    <a className="scroll-down" href={'#' + props.anchor}>
      <div className="text">
        Scroll
      </div>
      <div className="scroll-box" />
    </a>
  )
}

MouseAnimation.propTypes = {
  anchor: PropTypes.string.isRequired
}

export default MouseAnimation
