import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import { useAtTop } from '@gluedigital/scrollastic'

import './Header.sass'

export const Header = (props) => {

  const [menu, setMenu] = useState(false)

  const handleMenu = useCallback(
    () => {
      setMenu(!menu)
    },
    [menu]
  )

  let classToggleMenu = ''
  if (menu) {
    classToggleMenu += 'active'
  }

  const activeClass = useAtTop() ? '' : ' active'

  const button = props.pageName === 'map'
    ? (<Link className="button map" to="/">
      <span className="icon icon-map-pin" />
      <FormattedMessage id="header.link.exit-map" />
    </Link>)
    : (<Link className="button map" to="/map">
      <span className="icon icon-map-pin" />
      <FormattedMessage id="header.link.map" />
    </Link>)

  let icon = 'icon icon-menu'
  if (props.pageName === 'map') {
    icon += ' black'
  }
  if (props.pageName === 'experience' || props.pageName === 'route') {
    return false
  } else {
    return (
      <header id="masthead" className={props.pageName + activeClass}>
        <div className="container">
          <div className="left slot">
            {props.left}
          </div>
          <Link className="logo center slot" to="/" />
          <div className="right slot">
            {props.right}
            {button}
            <span className={icon} onClick={handleMenu} />
          </div>
        </div>
        <nav className={classToggleMenu} onClick={handleMenu}>
          <div className="nav-content">
            <span className="icon icon-cross" onClick={handleMenu} />
            <div className="logo-menu" />
            <div className="links">
              <Link className="link" to="/">
                <FormattedMessage id="header.link.home" />
              </Link>
              <Link className="link" to="/map">
                <FormattedMessage id="header.link.map" />
              </Link>
              <Link className="link" to="/promotions">
                <FormattedMessage id="header.link.promotions" />
              </Link>
              <Link className="link" to="/about">
                <FormattedMessage id="header.link.about" />
              </Link>
            </div>
            <div className="social">
              <a target="_blank" href="https://facebook.com/vibeviajes/" rel="noopener">  
                <span className="icon icon-facebook" />
              </a>
              <a target="_blank" href="https://www.instagram.com/vibeviajes/" rel="noopener">
                <span className="icon icon-instagram" />
              </a>
              <a target="_blank" href="mailto:info@vibeviajes.com">
                <span className="icon icon-mail" />
              </a>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

Header.propTypes = {
  pageName: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node
}

export default (Header)
