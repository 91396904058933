import React from 'react'
import { Helmet } from 'react-helmet'
import config from 'src/config'
import ogImage from 'src/static/images/og-image.jpg'

const title = `
Vibe Travel. Viaja diferente
`

const description = `
Viajes y experiencias por todo el mundo. Expertos en viajes a medida, diferentes y 煤nicos
`

const keywords = `
viajes, viajes a medida, hotel, Hoteles, ofertas especiales, paquetes, especiales, escapadas de fin de semanas,
escapadas en la ciudad, vacaciones, ofertas especiales, descuentos, barato
`

const externalUrl = encodeURI(config.EXTERNAL_URL)

export const Metas = () => (
  <React.Fragment>
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content: description
        }, {
          name: 'google-site-verification',
          content: '1FNuVqZnMkPzJGrL3fJ8oFWCfLUqVs9uXvg0IpVm6jA'
        }, {
          name: 'keywords',
          content: keywords
        }, {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        }, {
          property: 'og:title',
          content: title
        }, {
          property: 'og:type',
          content: 'website'
        }, {
          property: 'og:url',
          content: externalUrl
        }, {
          property: 'og:image',
          content: externalUrl + ogImage
        }, {
          property: 'og:image:width',
          content: '1200'
        }, {
          property: 'og:image:height',
          content: '630'
        }, {
          property: 'og:description',
          content: description
        }, {
          name: 'twitter:card',
          content: 'summary'
        }
      ]}
    />
  </React.Fragment>
)

export default Metas
