import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './Map.sass'
import { injectIntl } from 'react-intl'
import { getExperiences } from 'src/store/actions/experiences'
import ExperienceIcon from './ExperienceIcon'
import config from 'src/config'

// We can't import Leaflet as usual because it breaks on server-side.
// Also, we can't conditionally import, so we have to fall back to require
// import L from 'leaflet'
const L = __CLIENT__ ? require('leaflet') : null

// Reverse z-index ordering so hover cards work properly
if (__CLIENT__) {
  L.Marker.prototype.__setPos = L.Marker.prototype._setPos
  L.Marker.prototype._setPos = function (pos) {
    L.Marker.prototype.__setPos.call(this, pos)
    this._zIndex = 5000 - pos.y
    this._resetZIndex()
  }
}

const Map = ({ experiencesReq, router, intl }) => {
  const shadowUrl = config.PLACEHOLDER_COLOR
  const mapStyle = { backgroundImage: 'url("' + shadowUrl + '")' }
  const divMap =
    <div id="experience-map">
      <div id="map" style={mapStyle} />
    </div>

  const experiences = experiencesReq.value || experiencesReq.oldValue
  if (!experiences) {
    if (experiencesReq.isError) return 'Error'
    return divMap
  }

  const theMap = useRef(null)
  const [markers, setMarkers] = useState([])

  const updateMap = () => {
    if (!theMap.current) {
      let southWest = L.latLng(-75.93489090881157, -169.64263916015625)
      let northEast = L.latLng(81.30499982340113, 190.54687500000003)
      let bounds = L.latLngBounds(southWest, northEast)

      theMap.current = L.map('map', {
        boxZoom: false,
        keyboard: false,
        zoomSnap: 0.10,
        zoomControl: false,
        maxBounds: bounds,
        maxZoom: 19,
        minZoom: 2.5,
        worldCopyJump: true
      })
      const osmUrl =
        'https://api.mapbox.com/styles/v1/dianareinoso/cjs09c6bt1gxd1fn6cn4o10ab' +
        '/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGlhbmFyZWlub' +
        '3NvIiwiYSI6ImNpd25nM2xnazAwMTcydHAxOGgycDAwcjAifQ.-XOqK4JIZpbF55dv' +
        '_xzq2Q'

      const center = [30, -3.703790]
      const zoom = 3
      theMap.current.setView(center, zoom)
      new L.TileLayer(osmUrl, {}).addTo(theMap.current)
    }

    markers && theMap.current.removeLayer(markers)
    experiences.forEach((v) => {
      const icon = ExperienceIcon({
        experience: v,
        router,
        intl
      })
      const mk = L.marker(v.localization, { icon })
      setMarkers(mk)
      mk.addTo(theMap.current)
    })
  }

  useEffect(() => { updateMap() }, [experiencesReq])

  return (
    <div id="experience-map">
      <div id="map" />
    </div>
  )
}

Map.fetchData = (props, dispatch) => dispatch(getExperiences())

Map.propTypes = {
  experiencesReq: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ experiencesReq: state.experiences.experiences || {} })
export default injectIntl(connect(mapStateToProps)(Map))
