import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { getImageSize } from 'src/util'
import ContactForm from 'src/components/ContactForm'

import './ExperienceContactModal.sass'

const ExperienceData = ({ experience }) => {
  return (
    <div id="experience-data">
      <h1 className="title">{experience.name}</h1>
      <div className="data">
        <div className="breadcrumb">
          <span className="icon icon-world" />
          <span className="crumb">{experience.continent || 'Continente'}</span>
          <span className="crumb">{experience.country || 'País'}</span>
          <span className="crumb">{experience.city || 'Ciudad'}</span>
        </div>
        <div itemProp="price" className="price">
          <span className="icon icon-money" />
          <div className="price-wrapper">
            <div className="since"><FormattedMessage id="since" /></div>
            <MarkdownMessage id="header.price" values={{ n: experience.price || '000' }} />
          </div>
        </div>
      </div>
      <p className="description" dangerouslySetInnerHTML={{ __html: experience.contactDescription }} />
    </div>
  )
}
ExperienceData.propTypes = {
  experience: PropTypes.object
}

const ExperienceContactModal = ({ experience }) => {

  const url = getImageSize(experience.background, 'big')
  const style = url ? { backgroundImage: 'url("' + url + '")' } : null

  const [showData, setShowData] = useState(false)
  const handleData = useCallback(
    () => {
      setShowData(!showData)
    },
    [showData]
  )

  let classToggleData = 'split'
  let classSwitcher = 'switcher show-form'
  if (showData) {
    classToggleData += ' show-data'
    classSwitcher = 'switcher show-info'
  }

  return (
    <div className="experience-contact">
      <div className={classToggleData}>
        <div className={classSwitcher} onClick={handleData}>
          <FormattedMessage id="form" />
          <FormattedMessage id="info" />
        </div>
        <div className="item left" style={style}>
          <div className="veil" />
          <ExperienceData experience={experience} />
        </div>
        <div className="item right">
          <ContactForm sourceType="experience" sourceId={experience.slug} />
        </div>
      </div>
    </div>
  )
}

ExperienceContactModal.propTypes = {
  experience: PropTypes.object
}

registerModal('experience-contact', ExperienceContactModal)
