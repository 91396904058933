import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import MouseAnimation from 'src/components/MouseAnimation'
import SocialFloater from 'src/components/SocialFloater'
import config from 'src/config'
import ogImage from 'src/static/images/about-banner-bg.jpg'

import './About.sass'

export const About = () => {
  const externalUrl = encodeURI(config.EXTERNAL_URL)
  const mapUrl = 'https://goo.gl/maps/GWfncap4BVViWRa8A'
  return (
    <React.Fragment>
      <Helmet
        title="Vibe - Una agencia diferente, que hace cosas diferentes."
        meta={[
          {
            property: 'og:title',
            content: 'Una agencia diferente, que hace cosas diferentes.'
          },
          {
            property: 'og:url',
            content: externalUrl + '/about/'
          },
          {
            property: 'og:image',
            content: externalUrl + ogImage
          }
        ]}
      />
      <div id="about" className="page">
        <header id="about-header">
          <div className="veil" />
          <div className="data">
            <h1 className="title">
              <FormattedMessage id="about.title" />
            </h1>
            <SocialFloater location="/about" title="Vibe - Una agencia diferente, que hace cosas diferentes" />
          </div>
          <MouseAnimation anchor="intro" />
        </header>
        <section id="intro">
          <div className="container">
            <h1><MarkdownMessage id="about.intro.title" /></h1>
            <div className="split">
              <div className="item left">
                <p><FormattedMessage id="about.intro.text-1" /></p>
              </div>
              <div className="item right">
                <p><FormattedMessage id="about.intro.text-2" /></p>
              </div>
            </div>
          </div>
        </section>
        <section id="banner">
          <div className="veil" />
          <h1><FormattedMessage id="about.banner.title" /></h1>
        </section>
        <section id="contact">
          <h1><FormattedMessage id="about.contact.title" /></h1>
          <p><MarkdownMessage id="about.contact.text" /></p>
          <div className="contact-map">
            <div className="contact-card">
              <div className="row">
                <span className="icon icon-location" />
                <div className="wrapper">
                  <a target="_blank" href={mapUrl}><MarkdownMessage id="about.contact.address" /></a>
                </div>
              </div>
              <a className="row" data-whatsapp="False" data-telf="986 954 973" href="tel:+34685526400">
                <span className="icon icon-phone" /> 685 526 400
              </a>
              <a className="row" href="mailto:info@vibeviajes.com"><span className="icon icon-mail" />
                info@vibeviajes.com
              </a>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default About
