import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, browserHistory } from 'react-router'
import { FormattedMessage } from 'react-intl'
import Scroll from 'scroll'
import scrollDoc from 'scroll-doc'

import MarkdownMessage from '@gluedigital/markdown-message'
import { useModal } from '@gluedigital/modal'
import { getExperience } from 'src/store/actions/experiences'
import Header from 'src/components/Header'
import config from 'src/config'
import SocialFloater from 'src/components/SocialFloater'
import MouseAnimation from 'src/components/MouseAnimation'
import NotFound from 'src/routes/NotFound'
import { getImageSize } from 'src/util'
import './ExperienceContactModal'
import ogImage from 'src/static/images/default-experience-image.jpg'

import 'isomorphic-fetch'
import './Experience.sass'

const headerRef = React.createRef(null)
const scrollElement = scrollDoc()

const Left = ({ experience }) => {
  return (
    <div className="experience-data">
      <div className="breadcrumb">
        <span className="icon icon-world" />
        <span className="crumb">{experience.continent || 'Continente'}</span>
        <span className="crumb">{experience.country || 'País'}</span>
        <span className="crumb">{experience.city || 'Ciudad'}</span>
      </div>
      <div itemProp="price" className="price">
        <span className="icon icon-money" />
        <div className="price-wrapper">
          <div className="since"><FormattedMessage id="since" /></div>
          <MarkdownMessage id="header.price" values={{ n: experience.price || '000' }} />
        </div>
      </div>
    </div>
  )
}

Left.propTypes = {
  experience: PropTypes.object.isRequired
}

const Right = ({ pageName, experience }) => {
  const modal = useModal()

  const handlerScroll = (e) => {
    e.preventDefault()
    browserHistory.push(switchHref)
    Scroll.top(scrollElement, headerRef.current.offsetHeight - 75, { duration: 500 })
  }

  const showContact = () => modal.show('experience-contact', { experience })

  const switchHref = '/experience/' + experience.slug + (pageName === 'experience' ? '/route' : '')
  const switchText = pageName === 'experience' ? 'route' : 'experience'
  return (
    <div className="experience-actions">
      <Link className="button switch" onClick={handlerScroll} >
        <FormattedMessage id={'experiences.button.' + switchText} />
      </Link>
      <Link className="button hire" onClick={showContact}>
        <FormattedMessage id="experiences.button.hire" />
      </Link>
    </div>
  )
}

Right.propTypes = {
  experience: PropTypes.object.isRequired,
  pageName: PropTypes.string
}

const Experience = ({ children, experienceReq, experiencesReq, routes, params }) => {
  const modal = useModal()
  const experiences = experiencesReq.value
  const experiencesFilter = experiences && experiences.find((exp) => exp.slug === params.experienceId)
  let experience = experienceReq.value || experienceReq.oldValue || experiencesFilter
  if (!experience) {
    if (experienceReq.isLoading) return 'Loading'
    if (experienceReq.isError) return <NotFound />
    return false
  }
  const showContact = () => modal.show('experience-contact', { experience })

  const url = getImageSize(experience.background, 'big')
  const style = url ? { backgroundImage: 'url("' + url + '")' } : null

  const externalUrl = encodeURI(config.EXTERNAL_URL)

  return (
    <React.Fragment>
      <Helmet
        title={experience.name}
        meta={[
          {
            name: 'description',
            content: experience.contactDescription || 'Vibe - Travel different'
          },
          {
            property: 'og:title',
            content: experience.name
          },
          {
            property: 'og:description',
            content: experience.contactDescription || 'Vibe - Travel different'
          },
          {
            property: 'og:url',
            content: externalUrl + '/experience/' + experience.slug
          },
          {
            property: 'og:image',
            content: url || externalUrl + ogImage
          }
        ]}
      />
      <Header
        left={<Left experience={experience} />} pageName="experiences"
        right={<Right experience={experience} pageName={routes[routes.length - 1].title} />}
      />
      <article id="experience" className={'page' + ' experience-' + experience.slug}
        itemScope itemType="http://schema.org/BlogPosting">
        <header id={'header-' + experience.slug} className="experience-header" style={style} ref={headerRef}>
          <div className="veil" />
          <div className="data">
            <h1 itemProp="name" className="title">{experience.name}</h1>
            <SocialFloater location={'/experience/' + experience.slug} title={experience.name} />
            <div itemProp="price" className="price">
              <MarkdownMessage id="home.super-featured.price" values={{ n: experience.price || '000' }} />
            </div>
            <div className="hire">
              <Link className="button" onClick={showContact}>
                <FormattedMessage id="experiences.button.hire" />
              </Link>
            </div>
          </div>
          <MouseAnimation anchor="experience-body" />
        </header>
        {children}
      </article>
    </React.Fragment>
  )
}

Experience.fetchData = async (props, dispatch) =>
  dispatch(getExperience(props.params.experienceId, props.location.query.access))
    .catch(e => e.message === 'Not Found' && { __serverDirectives: { status: 404 } })

Experience.propTypes = {
  experienceReq: PropTypes.object.isRequired,
  experiencesReq: PropTypes.object.isRequired,
  children: PropTypes.node,
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  experienceReq: state.experiences.experience || {},
  experiencesReq: state.experiences.experiences || {}
})
export default connect(mapStateToProps)(Experience)
