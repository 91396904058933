import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import MarkdownMessage from '@gluedigital/markdown-message'
import { CloseModal } from '@gluedigital/modal'
import { contact } from 'src/store/actions/experiences'

import './ContactForm.sass'

const ContactForm = ({ sourceType, sourceId, contact }) => {
  const [error, setError] = useState(false)
  const [finished, setFinished] = useState(false)
  const [accepted, setAccepted] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(false)

    const fields = ['name', 'email', 'origin', 'adults', 'children', 'phone']
    const values = { sourceType, sourceId }
    fields.forEach(field => { values[field] = e.target[field].value })

    try {
      await contact(values)
      setFinished(true)
    } catch (e) {
      setError('error-sending')
      console.warn(e)
    }
  }

  if (finished) {
    return (
      <div id="finished">
        <div className="wrapper">
          <p><FormattedMessage id="contact.finished.sended" /></p>
          <h1><FormattedMessage id="contact.finished.asap-contact" /></h1>
          <CloseModal className="button" ><FormattedMessage id="comeback" /></CloseModal>
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="box">
      <div className="logo" />
      <h2 className="form-title">
        <FormattedMessage id="contact.form-title" />
      </h2>
      <label className="required">
        <FormattedMessage id="contact.name" >
          {(text) =>
            <input
              type="text"
              name="name"
              required
              placeholder={text}
            />
          }
        </FormattedMessage>
      </label>
      <label className="required">
        <FormattedMessage id="contact.email" >
          {(text) =>
            <input
              type="text"
              name="email"
              required
              placeholder={text}
            />
          }
        </FormattedMessage>
      </label>
      <label className="required">
        <FormattedMessage id="contact.origin" >
          {(text) =>
            <input
              type="text"
              name="origin"
              required
              placeholder={text}
            />
          }
        </FormattedMessage>
      </label>
      <div className="flex-wrapper">
        <label className="required">
          <FormattedMessage id="contact.adults" >
            {(text) =>
              <input
                type="number"
                name="adults"
                min="0"
                required
                placeholder={text}
              />
            }
          </FormattedMessage>
        </label>
        <label>
          <FormattedMessage id="contact.children" >
            {(text) =>
              <input
                type="number"
                name="children"
                min="0"
                placeholder={text}
              />
            }
          </FormattedMessage>
        </label>
      </div>
      <label className="phone">
        <FormattedMessage id="contact.phone" />
        <input name="phone" type="tel" />
      </label>

      <div className="consent">
        <label>
          <input onClick={(e) => setAccepted(e.target.checked)} type="checkbox" />
          <span />
        </label>
        <div className="text">
          <Link target="_blank" to="/privacy"><MarkdownMessage id="contact.privacy" /></Link>
          <FormattedMessage id="contact.consent" />
        </div>
      </div>
      <button type="submit" disabled={!accepted} >
        <FormattedMessage id="contact.send" />
      </button>
      {error && (
        <div className="form-error">
          <FormattedMessage id={'contact.' + error} />
        </div>
      )}
    </form>
  )
}

ContactForm.propTypes = {
  contact: PropTypes.func.isRequired,
  sourceType: PropTypes.string.isRequired,
  sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default connect(null, { contact })(ContactForm)
